#privacy-policy-container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    text-align: left;
    border-radius: 8px;
}
#privacy-policy-container h1 {
    color: #333;
    text-align: center;
    padding: 20px 0;
}
.section-title {
    color: #173165;
    margin-top: 20px;
}
.section-content {
    margin-bottom: 10px;
}
.privacy-list {
    margin-left: 20px;
}
.privacy-list-item {
    margin-bottom: 10px;
}
.nested-list {
    margin-left: 20px;
}
.privacy-link {
    color: #0066cc;
}