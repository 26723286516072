body {
  margin: 0;
  padding: 0;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: black;
  background: #ffffff;
  scroll-behavior: smooth;
  height: 100%;
}
.background {
  position: relative;
  min-height: 100vh;
  overflow: hidden;
}
.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.star {
  position: absolute;
  border-radius: 50%;
}
.star-white {
    background-color: #ffffff;
    animation: twinkle 2s infinite ease-in-out;
}
.star-purple {
    background-color: #6f00ff;
    animation: twinkle 2s infinite ease-in-out;
}
@keyframes twinkle {
    0%, 100% { opacity: 0.5; }
    50% { opacity: 1; }
}
.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 2em;
  padding-top: 1em;
}
.thee3-company {
  text-align: center;
  padding: 2em;
  /* background: rgba(0, 0, 0, 0.7); */
  border-radius: 10px;
  margin: 0.5em auto 1em auto;
  width: 80%;
  max-width: 800px;
}
.thee3-company h1 {
  color: #173165;
}
.thee3-logo {
  width: 600px;
  margin-bottom: 20px;
}
.slogan {
  font-style: italic;
  margin-bottom: 10px;
  color: #4c3790;
}
hr {
  border: 1px solid #000000;
  margin: 20px auto;
  width: 50%;
}
.description {                                                                                                                                                                       
  margin-bottom: 50px;
  text-align: center;
  line-height: 1.5;
  font-size: 16px;
  padding: 20px;
}
.list {
  list-style-type: none;
  padding: 0;
}
.list li {
  margin: 10px 0;
}
.more-options {
  display: flex;
  justify-content: space-around;
  padding: 2em;
  position: relative;
}
.thee3-option {
  border-radius: 50px;
  width: 30%;
  text-align: center;
  padding: 1em;
}
.thee3-option a {
  text-decoration: none;
  color: black;
}
.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thee3-option {
  width: 100%;
  max-width: 250px;
  border: 1px solid #029fe3;
  padding: 20px;
  box-shadow: 11px 11px 5px #2a2b72;
  margin-bottom: 20px;
  text-align: center;
}
.store-option {
  border-right: none;
}
.blog-option {
  border-left: none;
}                  
.changing-words > span {
  animation: changeWords 20s infinite;
}
@keyframes changeWords {
  0% {
      opacity: 0;
  }
  5% {
      opacity: 1;
  }
  25% {
      opacity: 1;
  }
  30% {
      opacity: 0;
  }
}
.opt-desc {
  text-align: left;
  color: #173165; 
}
.opt-desc2 {
  text-align: left;
  color: #4c3790;
}
.footer-nav {
  display: flex;
  justify-content: center;
  padding: 1em;
  margin-top: 250px;
}
.footer-nav a {
  margin: 0 10px;
  text-decoration: none;
  color: black;
}
.footer-nav a:hover {
  text-decoration: underline;
}
.terms h1, .terms h2 {
  color: #173165;
  text-align: left;
}
.terms p {
  margin-bottom: 15px;
  text-align: left;
}
.aboutus p {
  text-align: left;
}
@media only screen and (max-width: 440px) {
  .thee3-logo {
    width: 290px !important;
  }
} 
@media only screen and (max-width: 321px) {
  .thee3-logo {
    width: 200px !important;
  }
  .store-option {
    width: 90%;
  }
  .blog-option {
    width: 90%;
  }   
} 